.App {
	background: linear-gradient(
		90deg,
		#f7fafd 30.73%,
		rgba(235, 242, 253, 0.78) 58.55%,
		rgba(214, 230, 254, 0) 100%
	);
	background-size: cover;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

@media (min-width: 1350px) {
	.App {
		width: calc(97% - 0px);
	}
}

.App::-webkit-scrollbar {
	display: none;
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
}

h1 {
	margin-top: 0px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.btn {
	padding-top: 52px;
	width: -webkit-fill-available;
	padding: 14px 109px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	border-radius: 5px;
	background: #0360f0;
	color: #fff;
	text-align: center;
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: 0.409px;
	border: none;
}

@media (max-width: 822px) {
	.App {
		background: linear-gradient(
			113deg,
			#fff 0.52%,
			#f7fafd 5.53%,
			#b0c9ef 74.02%,
			#0360f054 100%
		);
	}
	body {
		background: linear-gradient(
			113deg,
			#fff 0.52%,
			#f7fafd 5.53%,
			#b0c9ef 74.02%,
			#0360f054 100%
		);
		height: 100px;
	}
}
