.iv-bottom {
	display: flex;
	padding-left: 33px;
	justify-content: flex-start;
	align-items: center;
	position: fixed;
	bottom: 15px;
	padding-left: max(1%, 33px);
}

.ivBottomText {
	color: var(--Primary-Blue, #003049);
	font-family: Poppins;
	font-size: 15.669px;
	font-style: normal;
	font-weight: 300;
	line-height: 8.683px; /* 55.414% */
	margin-bottom: -3px;
}

.IvBottom {
	cursor: pointer;
}

@media (max-width: 768px) {
	.iv-bottom {
		padding-left: 0px;
		margin: 0 auto;
		width: 100%;
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.bottom-bar {
		background: linear-gradient(90deg, #fff 30.73%, rgb(255 255 255) 100%);
	}
}

@media (max-width: 375px) {
	.iv-bottom {
		bottom: 13px;
		left: 23px;
	}
}

.bottom-bar {
	background: linear-gradient(90deg, #fff 30.73%, rgb(255 255 255) 100%);
	bottom: 0;
	position: fixed;
	width: 100%;
	height: 60px;
}
