span.headText1 {
	color: #003049;
	font-family: Poppins;
	font-size: 37.796px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.756px;
}

.containerHead {
	padding-left: 10px;
}

form > div:nth-child(12) > div:nth-child(2) > svg > foreignObject > input {
	width: 80px;
}

form > div:nth-child(13) > div:nth-child(2) > svg > foreignObject > input {
	width: 120px;
}

.wrapperHead {
	background: linear-gradient(
		90deg,
		#f7fafd 30.73%,
		rgba(235, 242, 253, 0.78) 58.55%,
		rgba(214, 230, 254, 0) 100%
	);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: max(1%, 40px);
	padding-top: max(1.5%, 10px);
}

.form > .containerHead {
	display: inline-block;
	width: 32%; /* of wrapper width */
	margin: auto;
	text-align: left;
}

span.rapText.filter {
	display: flex;
	visibility: visible;
	padding-right: 30px;
	font-size: 23px;
	padding-bottom: 0px;
}

.rapRightFilter {
	display: flex;
	padding-right: max(1%, 10px);
}

.headerTop {
	position: static;
	background: white;
	width: 100%;
}

@media (max-width: 1000px) {
	.wrapperHead {
		background: linear-gradient(
			226deg,
			#f6f6f6 30.73%,
			rgb(235 242 253) 58.55%,
			rgb(255 255 255) 100%
		);
	}
}

@media (max-width: 822px) {
	.wrapperHead {
		display: flex;
		padding-top: max(3%, 20px);
		padding-left: 0px;
		justify-content: center;
		flex-direction: column;
		background: linear-gradient(
			226deg,
			#f6f6f6 30.73%,
			rgb(235 242 253) 58.55%,
			rgb(255 255 255) 100%
		);
	}
	svg {
		max-width: 350px;
	}
	a:-webkit-any-link {
		color: #003049;
	}
}

@media (min-width: 1350px) {
	.wrapPageBackgroundMobile {
		display: none;
	}
	.wrapperHead {
		padding-top: max(0.5%, 10px);
	}
}
