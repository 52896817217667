.react-international-phone-input-container {
	display: inline-flex;
	width: 100px;
}

.wrapPage {
	height: 100%;
	margin: 0px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.nameForm {
	padding-top: 71px;
	padding-left: 98px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.rapText {
	color: #003049;
	font-family: Poppins;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.48px;
	padding-bottom: 22px;
}

foreignObject {
	background: #c5d5f03d;
}

.row.mb-4 {
	margin-bottom: 0px !important;
	margin-top: 10px;
}

.rapText.trending {
	padding-top: 60px;
	visibility: hidden;
}

form > div:nth-child(9) > div:nth-child(2) > svg > foreignObject > input {
	width: 85px;
}

.react-international-phone-input {
	width: 60px;
}

#phone-input {
	vertical-align: top;
	margin-left: 10px;
	margin-top: 9px;
	width: 230px;
	padding-left: 0px;
	outline: none;
	text-align: left;
}

::placeholder {
	color: #8d939c;
	opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
	color: #8d939c;
}

.input {
	display: flex;
	width: 265px;
	align-items: center;
	margin-top: 6px;
	margin-left: 2px;
}

.rapBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.messages {
	display: flex;
	justify-content: center;
}

.input.input {
	background: #f4f8ff00;
	outline: none;
	border: none;
	padding: 13px 16px;
	color: #003049;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px; /* 100% */
}

.rapBorderBox {
	padding: 80px 602px 327px 602px;
}

.rapInputs {
	backdrop-filter: blur(2px);
	max-height: 538px;
	width: 356px;
	padding: 45px;
	border-radius: 19px;
	background: rgba(255, 255, 255, 0.7);
	gap: 20px;
}

span.signUp,
span.signIn,
span.signUp > a,
span.signIn > a {
	color: #0360f0;
	font-family: Poppins;
	font-size: 17px;
	font-style: normal;
	line-height: 20px; /* 117.647% */
}

a:link,
a:-webkit-any-link {
	text-decoration: none;
	color: var(--Primary-Blue, #003049);
}

.rapSpanSign {
	display: flex;
	height: 66px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.rapSpanSignIn,
.rapSpanSignUp {
	padding: 18px 32px;
	border-bottom: 1px solid #8f9ca3;
}

.headSign {
	display: flex;
	justify-content: center;
	align-items: center;
	color: #0360f0;
	text-align: center;
	font-family: Poppins;
	font-size: 35px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.7px;
}

#sumbitBtn {
	margin-top: 52px;
	width: 359px;
}

.rapForm {
	padding-right: max(13%, 145px);
	margin-top: max(1%);
}
.wrapperBodyText {
	background-image: url("../assets/ecom2.png");
}

.wrapFormText {
	background: ("../assets/ecom2.png");
}

.card {
	box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 0 solid rgba(0, 0, 0, 0.125);
	border-radius: 1rem;
}

.form-rap {
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1.5rem 1.5rem;
	background: linear-gradient(
		90deg,
		#f7fafd 30.73%,
		rgba(235, 242, 253, 0.78) 58.55%,
		rgba(214, 230, 254, 0) 100%
	);
}

.rapText.sum {
	padding-top: 51px;
}

.rapsum {
	max-width: 600px;
	padding-right: 100px;
	margin-top: -13px;
}

@media (max-width: 450px) {
	.input {
		width: 197px;
	}
}

.labelOver {
	display: block;
	color: var(--Primary-Blue, #003049);
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

input.input.mobile {
	width: 288px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.wrapPageMobile {
	display: flex;
	flex-direction: column;
	align-content: center;
	align-items: center;
	justify-content: center;
}

.wrapFormTextMobile {
	width: 260px;
}

.wrapperBodyText {
	background: linear-gradient(73deg, #340c3d 37.28%, #ac357c 70.75%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	width: 670px;
	position: fixed;
	top: 30%;
	left: 13%;
}

.bodyText {
	font-family: Poppins;
	font-size: 46.222px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.924px;
}

.bodyTextSignIn,
.bodyTextSignUp {
	font-family: Poppins;
	font-size: 40px;
	max-width: 450px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.924px;
	background: linear-gradient(73deg, #340c3d 37.28%, #ac357c 70.75%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.bodyTextSignUp {
	max-width: 430px;
}

.react-international-phone-input-container {
	padding-top: 10px;
	padding-left: 5px;
}

input.react-international-phone-input {
	border: 1px solid #f2f5fa !important;
	color: #8d939c !important;
	font-size: 19px !important;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.21px;
}

.react-international-phone-input-container
	.react-international-phone-country-selector-button {
	padding: 25px 0px 28px 0px;
	margin: -8px 0px 0px -3px;
}

@media (max-width: 822px) {
	.rapForm {
		padding-right: 0px;
		margin-top: 20px;
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.wrapPage {
		display: none;
	}
	label.label {
		padding-top: 2px;
	}
	#sumbitBtn {
		margin-top: 12px;
		width: 321px;
	}
	.wrapperBodyText {
		width: auto;
		padding-left: 0px;
		padding-top: 0%;
		width: auto;
	}
	.bodyTextSignIn,
	.bodyTextSignUp {
		font-size: 28px;
		text-align: center;
	}
	.wrapFormTextMobile {
		margin-top: -100px;
	}
	#root
		> div
		> div.wrapPageMobile
		> div.rapForm
		> form
		> div
		> svg:nth-child(8)
		> foreignObject
		> div
		> input {
		box-sizing: content-box;
		width: 2.7em;
		pointer-events: none;
		cursor: not-allowed;
	}
}

@media (max-width: 820px) and (min-height: 1024px) {
	.bodyTextSignIn,
	.bodyTextSignUp {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.custom-modal {
	position: relative;
	z-index: 999;
}
.custom-modal .content {
	position: absolute;
	width: 100%;
	text-align: center;
	bottom: 0;
}
.custom-modal .content .type {
	font-size: 18px;
	color: #999;
}
.custom-modal .content .message-type {
	font-size: 24px;
	color: #000;
}
.custom-modal .border-bottom {
	position: absolute;
	z-index: 9999;
}
.custom-modal .icon-top {
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	top: -60px;
	margin: 0 125px;
	font-size: 30px;
	color: #fff;
	line-height: 100px;
	text-align: center;
}

.page-wrapper {
	height: 100vh;
	background-color: #eee;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 80px 0;
}
body {
	margin: 0;
	font-family: "Roboto";
}
@media only screen and (max-width: 800px) {
	.page-wrapper {
		flex-direction: column;
	}
}

.react-international-phone-input-container .react-international-phone-input,
input.react-international-phone-input {
	font-family: Roboto Flex !important;
}

#root
	> div
	> div.wrapPage
	> div.wrapFormText
	> div.rapForm
	> form
	> div
	> svg:nth-child(8)
	> foreignObject
	> div
	> input {
	box-sizing: content-box;
	width: 2.8em;
	pointer-events: none;
	cursor: not-allowed;
}

.messageSuccess,
.messageError {
	display: flex;
	justify-content: center;
	font-family: Poppins;
	font-size: 1.5em;
}

.errorBox {
	display: none;
	/* display: flex; */
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	width: 465px;
	height: 412px;
	flex-shrink: 0;
	background: rgba(255, 255, 255, 0.7);
	border-radius: 19px;
	background: var(--White, #fff);
	box-shadow: 0px 0px 20px 0px rgba(76, 130, 211, 0.2);
	position: absolute;
	top: 0;
	bottom: 40px;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 999;
}

.errorBox.mobile {
	width: auto;
	height: 450px;
}

.error-icon {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 20%;
}

span.react-international-phone-country-selector-dropdown__list-item-country-name,
span.react-international-phone-country-selector-dropdown__list-item-dial-code {
	font-family: "Poppins";
	font-size: 12px;
}

ul.react-international-phone-country-selector-dropdown {
	position: fixed;
	width: 100%;
	outline: none;
}

@media (min-width: 822px) and (max-width: 1366px) {
	.rapForm {
		padding-right: max(1%, 65px);
		margin-top: auto;
	}
	#sumbitBtn {
		margin-bottom: 110px;
		margin-top: 12px;
	}
}

@media (min-width: 2000px) {
	.wrapperBodyText {
		left: 22%;
	}
}

@media (min-width: 2800px) {
	.wrapperHead {
		padding-left: max(24%, 130px);
		padding-top: max(3%, 20px);
	}
}

@media (max-width: 750px) {
	.errorBox {
		max-width: 370px;
	}
}

.mb-4 {
	margin-bottom: 0.5rem !important;
}

@media (min-width: 768px) {
	.col-md-8 {
		flex: 0 0 auto;
		width: 43.666667%;
	}
}

.rapformer {
	display: flex;
	background: linear-gradient(
		90deg,
		#f7fafd 30.73%,
		rgba(235, 242, 253, 0.78) 58.55%,
		rgba(214, 230, 254, 0) 100%
	);
	justify-content: space-evenly;
	padding-bottom: 200px;
}

.rapformer.success {
	display: flex;
	justify-content: center;
	text-align-last: center;
	flex-direction: row;
}

.rapsum.success {
	padding-top: 20px;
	max-width: 600px;
	margin-top: -13px;
}

.dotcredit {
	display: flex;
	align-items: center;
	padding-bottom: 22px;
	padding-top: 10px;
}

label.labelOver.credit {
	padding-left: 5px;
}

#sumbitBtn {
	color: White;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 16px;

	border-radius: 19px;
	border: 1px solid #8fc7b8;
	background: #8fc7b8;
	width: 164px;
	height: 50px;
	flex-shrink: 0;
}

hr.linebrake {
	width: 100%;
	height: 1px;
	margin-top: 36px;
	margin-bottom: 36px;
}

hr.list-hr {
	margin-top: 24px;
	margin-bottom: 24px;
}

.rap-item-block {
	display: flex;
}

.rap-text {
	display: flex;
	flex-direction: column;
}

.item-text1 {
	display: flex;
	color: #003049;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: bold;
	line-height: normal;
	letter-spacing: -0.32px;
}

.item-text2,
.item-text3 {
	display: flex;
	color: #aaa;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.32px;
}

form > div:nth-child(8) > div:nth-child(1) > svg > foreignObject,
form > div:nth-child(8) > div:nth-child(2) > svg > foreignObject {
	background: #d4dfef4f;
}

#phone-input,
input[type="tel"],
button.react-international-phone-country-selector-button,
.react-international-phone-input-container .react-international-phone-input,
input.react-international-phone-input {
	background: #f2f5fa;
}

form > div:nth-child(8) > div:nth-child(2) > svg > foreignObject > div > input {
	width: 70px !important;
}

svg.experationYear {
	margin-left: 20px;
}

ul.listtext {
	padding-left: 0px;
}

.row {
	display: flex;
	justify-content: space-between;
}
.row.center {
	align-items: center;
}
.col-1 {
	flex: 1;
}
.col-2 {
	flex: 2;
}
.block {
	background-color: #e0e0e0;
	padding: 1rem;
	margin: 0.5rem;
	border-radius: 0.5rem;
}
.text-right {
	text-align: right;
}
img.small {
	width: 253.36px;
	height: 273.36px;
	padding-bottom: 17px;
	max-height: 18rem;
}

button {
	font-size: 0.8rem;
	padding: 0.2rem;
	margin: 0.1rem;
	border-radius: 0.5rem;
	border: 0.1rem #404040 solid;
	background-color: #f0c040;
	width: 100%;
	cursor: pointer;
}
button.add {
	background-color: #40c0f0;
	width: 1.5rem;
}
button.remove {
	background-color: #f04040;
	width: 1.5rem;
}
button.badge {
	background-color: #f04040;
	border: none;
	color: #ffffff;
	width: 1.5rem;
}

button.btn-products {
	width: 109px;
	height: 50px;
	flex-shrink: 0;
	border-radius: 19px;
	border: 1px solid #8fc7b8;
	background: #8fc7b8;
	margin-top: 17px;
}

.rap-item-block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 55px;
}

.rap-item-block-checkout {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}

img.imgCheckout {
	padding-right: 20px;
}

.rap-blocks-product {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	margin-left: 55px;
	bottom: 60px;
	position: fixed;
	width: 100%;
}

.wrapPageSignUp {
	max-width: 100%;
	-o-object-fit: contain;
	margin: 0;
	max-width: 100%;
	max-height: 100%;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	object-fit: contain;
	background-repeat: no-repeat;
	background-position: right;
	background-size: 70vw 70vh;
	width: 100vw;
	height: 80vh;
}

@media (max-width: 1025px) {
	.rap-blocks-product {
		bottom: 30px;
	}
	.rap-blocks-product {
		top: 32%;
	}
	.bodyTextSignUp {
		margin-top: -25%;
	}
}

.wrapPageBackground {
	background: linear-gradient(
		90deg,
		#f7fafd 30.73%,
		rgba(235, 242, 253, 0.78) 58.55%,
		rgba(214, 230, 254, 0) 100%
	);
}

.success.success {
	margin-top: 7%;
}

.rapText.header {
	visibility: visible;
}

.rapText.header.success {
	text-align: center;
}

.success.success {
	background: unset;
	box-shadow: none;
}

form {
	margin-bottom: 50px;
}

.iv-icon.dropdowns {
	display: inline-block;
}

svg.input.dropdown {
	background: white;
	width: 42px;
	padding: 0px;
	display: inline-block;
}

select#experationMonth,
select#experationYear {
	width: 80px;
	padding-top: 15px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.rapText.trending-top {
	margin-top: -50px;
	margin-right: -220px;
}

@media (max-width: 1230px) {
	.rapformer {
		flex-direction: column;
		background: linear-gradient(
			90deg,
			#f7fafd 30.73%,
			rgba(235, 242, 253, 0.78) 58.55%,
			rgb(255 255 255 / 71%) 100%
		);
	}
	.rap-summary {
		margin-top: -50px;
	}
	.rapsum {
		padding-right: 40px;
		margin-top: 0px;
		margin-left: 40px;
		margin-bottom: 80px;
	}
	.form-rap {
		padding-left: 20px;
	}
	.success.success {
		margin-top: 5%;
	}
	.rap-blocks-product {
		top: 0%;
		bottom: 0%;
		position: relative;
		flex-direction: column;
		margin-bottom: 140px;
	}
	main.blocks-items {
		background: linear-gradient(
			90deg,
			#f7fafd 30.73%,
			rgba(235, 242, 253, 0.78) 58.55%,
			rgb(255 255 255 / 71%) 100%
		);
	}
	.wrapFormText {
		padding-top: 140px;
		background: linear-gradient(
			90deg,
			#f7fafd 30.73%,
			rgba(235, 242, 253, 0.78) 58.55%,
			rgb(255 255 255 / 71%) 100%
		);
	}
}

@media (max-width: 1300px) {
	.wrapPageBackgroundMobile {
		padding-top: 20px;
		background: linear-gradient(
			183deg,
			#f7fafd 30.73%,
			rgb(240 246 255) 58.55%,
			rgb(205 193 193) 100%
		);
	}
	.wrapPageBackground {
		display: none;
	}
}

@media (max-width: 375px) {
	.bodyTextSignIn,
	.bodyTextSignUp {
		font-size: 27px;
	}
	.bodyTextSignUp {
		margin-top: -2%;
	}
	.headSign {
		font-size: 25px;
	}
}

@media (min-width: 820px) and (max-width: 1023px) {
	.rapsum {
		max-width: 600px;
		padding-right: 50px;
		margin-top: -13px;
	}
	.wrapperBodyText {
		top: 90%;
		left: 10%;
	}
	.rap-btn:last-of-type {
		margin-bottom: 10px;
	}
}

@media (min-width: 720px) and (max-width: 790px) {
	.bodyTextSignUp {
		margin-top: 30%;
	}
}

@media (min-width: 1050px) and (max-height: 1200px) {
	.wrapperBodyText {
		position: relative;
	}
}

@media (min-width: 1024px) and (max-height: 768px) {
	.wrapperBodyText {
		top: 37%;
	}
}

@media (max-width: 768px) and (min-height: 1024px) {
	.wrapperBodyText {
		top: 13%;
	}
}

@media (max-width: 900px) and (min-height: 1124px) {
	.wrapperBodyText {
		top: 23%;
	}
}

@media (min-width: 650px) and (max-width: 1023px) {
	.wrapperBodyText {
		margin-top: -62px;
		position: fixed;
	}
}

@media (min-height: 1024px) and (max-width: 768px) {
	.wrapperBodyText {
		margin-top: -162px;
		position: fixed;
	}
}

@media (min-width: 650px) and (max-width: 700px) {
	.wrapperBodyText {
		top: 80%;
	}
}

@media (max-width: 768px) {
	.App {
		background: linear-gradient(
			101deg,
			#f7fafd 30.73%,
			rgb(251 251 251 / 82%) 58.55%,
			rgb(255 255 255 / 70%) 100%
		);
	}
	.wrapFormText {
		background: linear-gradient(
			264deg,
			#f7fafd 30.73%,
			rgba(235, 242, 253, 0.78) 58.55%,
			rgb(255 255 255 / 71%) 100%
		);
	}
}

@media (max-width: 1366px) {
	.rapText.trending-top {
		margin-top: 0px;
		padding-top: 25px;
		font-size: 22px;
		margin-right: -220px;
	}
	button.btn-products {
		margin-bottom: 30px;
	}
}

@media (min-width: 1366px) and (max-width: 1400px) {
	.rapText.trending-top {
		margin-top: -100px;
	}
}

@media (min-width: 2200px) {
	.rap-blocks-product {
		bottom: 210px;
	}
}

@media (max-width: 1365px) {
	.wrapPageBackgroundDesktop {
		display: none;
	}

	.wrapPageBackgroundMob {
		background-color: white;
		position: sticky;
		top: -10px;
		height: auto;
		/* margin-bottom: 10px; */
		z-index: 9999999;
	}
}

@media (min-width: 1366px) {
	.wrapPageBackgroundMob {
		display: none;
	}
}

@media (max-width: 1367px) and (min-height: 1024px) {
	.wrapperBodyText {
		position: fixed;
		top: 20%;
	}
	.wrapPageBackgroundDesktop {
		display: none;
	}

	.wrapPageBackgroundMob {
		display: block;
		background-color: white;
		position: sticky;
		top: -10px;
		height: auto;
		z-index: 9999999;
	}
}
